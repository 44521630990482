import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

const Instructions = () => {
  const textData = {
    heartRisk: [
      "Heart disease is a serious condition that can lead to heart attacks, strokes, and other complications. This tool helps you estimate your risk of developing heart disease in the next 10 years based on your personal health information as by knowing your risk, you can take steps to prevent or lower it through lifestyle changes or medications.",
      "The tool will calculate your 10-year risk of heart disease using a formula based on the World Health Organization (WHO) report on the origins of COVID-19.",
      "The tool will also provide you with some suggestions on how to reduce your risk of heart disease, such as quitting smoking, eating a healthy diet, exercising regularly, and taking medications as prescribed by your doctor.",
      "This tool is intended for informational purposes only and does not replace professional medical advice. You should consult your doctor before making any changes to your health or treatment plan.",
    ],
    accuracy: [
      "Our Report Intelligence Assistance, Ria, will guide you through.",
      "Our Report Intelligence Assistance, Ria, will guide you through.",
      "Our Report Intelligence Assistance, Ria, will guide you through.",
      "Our Report Intelligence Assistance, Ria, will guide you through.",
    ],
  };

  return (
    <>
      <Box
        p={2}
        sx={{
          boxShadow: { lg: 3, xs: 0 },
          borderRadius: "16px",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
          Why you need to use this tool TODAY!
        </Typography>

        {textData.heartRisk.map((each, index) => (
          <Typography
            key={index}
            style={{
              fontSize: "14px",
              marginBottom: "10px",
              marginTop: "10px",
              color: "#737B7D",
            }}
          >
            {each}
          </Typography>
        ))}
      </Box>
    </>
  );
};

export default Instructions;
