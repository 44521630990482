import React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
// import ReactGA from "react-ga4";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate, useLocation } from "react-router-dom";
import Instructions from "./Instructions";
import niroggyan from "../assets/niroggyanWhite.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import HappyFace from "../HappyFace.png";
import SadFace from "../SadFace.png";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import useGAEventsTracker from "../GaEventTracker";

const UserForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { id, token, patient_age, patient_gender, total_cholesterol, risk } =
    Object.fromEntries(queryParams);
  const [age, setAge] = useState(patient_age ? patient_age : "");
  const [gender, setGender] = useState(
    patient_gender ? patient_gender.toLowerCase() : "male"
  );
  const [smoke, setSmoke] = useState("No");
  const [cholesterol, setCholesterol] = useState(
    total_cholesterol ? total_cholesterol : ""
  );
  const [bp, setBP] = useState("120");
  const [diabetes, setDiabetes] = useState("No");
  const [checked, setChecked] = useState(true);
  const [checkBoxError, setCheckBoxError] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const GAEventsTracker = useGAEventsTracker("risk score form page");

  const submitForm = async (e) => {
    e.preventDefault();
    if (!checked) {
      setCheckBoxError("Please check this box");
    }

    if (checked) {
      setCheckBoxError("");
    }
    const userData = {
      client_id: "ATHNG/RP/PD",
      name: "Niroggyan",
      phone: "1234567890",
      email: "test@gmail.com",
      age: age,
      gender: gender,
      smoke: smoke,
      cholesterol: cholesterol,
      blood_pressure: bp,
      diabetes: diabetes,
    };
    console.log("userData", userData);
    const apiUrl = "https://lab.athirahealth.com/api/laboratory";
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    };

    try {
      if (checked) {
        const response = await fetch(apiUrl, options);
        const data = await response.json();

        if (data.prediction) {
          GAEventsTracker("submitted", "successfully");
          GAEventsTracker("risk score data", data.prediction);

          Swal.fire({
            iconHtml: `<img src=${HappyFace}>`,
            customClass: {
              icon: "no-border",
            },
            width: "400px",
            title: "Success!",
            text: "Your risk score has been generated successfully.",
          });
          navigate("/riskScore", {
            state: { data: data, id: id, token: token },
          });
        } else {
          GAEventsTracker("submission", "unsuccessful");
          Swal.fire({
            iconHtml: `<img src=${SadFace}>`,
            customClass: {
              icon: "no-border",
            },
            width: "400px",
            title: "Error",
            text:
              data.error.age ||
              data.error.cholesterol ||
              data.error.blood_pressure,
          });
        }
      }
    } catch (e) {
      console.log("error", e);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: e,
      });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dialogBox = () => {
    return (
      <Dialog open={open} onClose={handleClose} fullWidth>
        {/* <IconButton
          aria-label="close"
          onClick={() => handleClose()}
          sx={{
            position: "absolute",
            right: 4,
            top: 4,
          }}
        >
          <CloseIcon />
        </IconButton> */}
        <DialogContent sx={{ padding: "30px 0px 0px 0px" }}>
          <IconButton
            aria-label="close"
            onClick={() => handleClose()}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContentText>
            <Instructions />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  };

  useEffect(() => {
    if (checked) {
      setCheckBoxError("");
    }
  }, [checked]);

  return (
    <Grid container spacing={2} sx={{ height: "100vh" }}>
      <Grid item lg={12} xs={12}>
        <Box
          sx={{
            backgroundColor: "#3567D6",
            padding: "12px 32px",
            width: "100%",
          }}
        >
          <img src={niroggyan} alt="logo" height="30px" />
        </Box>
      </Grid>
      <Grid item lg={12}>
        <Grid container spacing={1} justifyContent="space-evenly">
          <Grid item lg={6} sm={12}>
            <Box
              p={2}
              sx={{ boxShadow: { lg: 3, xs: 0 }, borderRadius: "16px" }}
            >
              <Stack direction="row" spacing={0.5}>
                {!risk ? (
                  <Typography variant="h4" sx={{ width: { xs: "83%" } }}>
                    Your Risk Assessment
                  </Typography>
                ) : (
                  <Stack direction="row" alignItems="center">
                    <Typography mr={2} sx={{ fontSize: "30px" }}>
                      {risk} Risk
                    </Typography>
                    <WarningAmberIcon
                      fontSize="large"
                      style={{ color: "red" }}
                    />
                  </Stack>
                )}
                <IconButton
                  sx={{ display: { lg: "none", xs: "" } }}
                  onClick={handleClickOpen}
                >
                  <InfoOutlinedIcon fontSize="medium" />
                </IconButton>
              </Stack>
              <Box>{dialogBox()}</Box>

              <Typography mt={2} sx={{ fontSize: "16px", color: "#737B7D" }}>
                Fill the following details to help us give you the most accurate
                10-year heart risk score, and also get useful diet & lifestyle
                recommendations.
              </Typography>
              <Box
                mt={3}
                component="form"
                noValidate
                autoComplete="off"
                onSubmit={submitForm}
              >
                <Grid container spacing={3}>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      fullWidth
                      size={"small"}
                      label="Age"
                      placeholder="age should be between 20-75"
                      variant="outlined"
                      onChange={(e) => setAge(e.target.value)}
                      value={age}
                      required
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <FormControl
                      onChange={(e) => setGender(e.target.value)}
                      value={gender}
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormLabel sx={{ marginRight: "16px", width: "70px" }}>
                        Gender
                      </FormLabel>
                      <RadioGroup row defaultValue={gender}>
                        <FormControlLabel
                          value="male"
                          control={<Radio sx={{ padding: "6px" }} />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="female"
                          control={<Radio sx={{ padding: "6px" }} />}
                          label="Female"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <FormControl
                      onChange={(e) => setSmoke(e.target.value)}
                      value={smoke}
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormLabel sx={{ marginRight: "16px", width: "70px" }}>
                        Smoke
                      </FormLabel>
                      <RadioGroup row defaultValue={smoke}>
                        <FormControlLabel
                          value="Yes"
                          control={<Radio sx={{ padding: "6px" }} />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio sx={{ padding: "6px" }} />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <FormControl
                      onChange={(e) => setDiabetes(e.target.value)}
                      value={diabetes}
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormLabel sx={{ marginRight: "16px", width: "70px" }}>
                        Diabetes
                      </FormLabel>
                      <RadioGroup row defaultValue={diabetes}>
                        <FormControlLabel
                          value="Yes"
                          control={<Radio sx={{ padding: "6px" }} />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio sx={{ padding: "6px" }} />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Total Cholesterol"
                      placeholder="Cholesterol should be less than 1000"
                      variant="outlined"
                      onChange={(e) => setCholesterol(e.target.value)}
                      value={cholesterol}
                      required
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Blood Pressure"
                      variant="outlined"
                      placeholder="BP should be between 80-330"
                      onChange={(e) => setBP(e.target.value)}
                      value={bp}
                      required
                      helperText="if it is 120/80, just fill 120"
                    />
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Box>
                      <Button
                        type="submit"
                        variant="contained"
                        color="success"
                        sx={{ width: "100%" }}
                      >
                        Generate Precise Risk Score
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        onChange={() => setChecked(!checked)}
                        checked={checked}
                      />
                      <Typography variant="p">
                        I acknowledge & agree that I’m sharing metabolic data
                        for accurate results.
                      </Typography>
                    </Stack>
                    {checkBoxError && (
                      <Typography
                        pl={5}
                        variant="p"
                        sx={{ color: "red", fontSize: "12px" }}
                      >
                        {checkBoxError}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            lg={4}
            sm={0}
            sx={{ display: { xs: "none", lg: "block" } }}
          >
            <Instructions />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserForm;
